
body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

h2 {
    text-align: center;
    color: #e74c3c;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-group button {
    width: 100%;
    padding: 10px;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-group button:hover {
    background-color: #c0392b;
}

.form-footer {
    text-align: center;
    margin-top: 10px;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.centered-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    height: 100vh;           /* Full viewport height */
    background-color: #f7f7f7; /* Light background color */
}

.content {
    background: #fff;        /* White background for the content */
    border-radius: 8px;      /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px;           /* Padding inside the content */
    text-align: center;      /* Center text */
    max-width: 400px;        /* Max width of the content */
    width: 100%;             /* Full width up to max-width */
}
